import * as React from "react";
import "./Footer.scss";
import GameloftLogo from "../../assets/images/footer/gameloft-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { SNS_LINKS, LEGAL_LINKS } from "../../utils";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube.svg";
import { ReactComponent as DiscordIcon } from "../../assets/images/discord.svg";

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="com-footer">
      <div className="icon-links">
        <a href={LEGAL_LINKS.gameloft} target="_blank" rel="noopener noreferrer">
          <img src={GameloftLogo} alt="Gameloft logo" className="gameloft-logo" />
        </a>
        <div className="sns-icons seo-footer-social-tracking-btn">
          <a className="facebook" href={SNS_LINKS.facebook} target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
          <a className="instagram" href={SNS_LINKS.instagram} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="3x" color="#ffffff" />
          </a>
          <a className="youtube" href={SNS_LINKS.youtube} target="_blank" rel="noopener noreferrer">
            <YoutubeIcon />
          </a>
          <a className="discord" href={SNS_LINKS.discord} target="_blank" rel="noopener noreferrer">
            <DiscordIcon />
          </a>
        </div>
      </div>
      <div className="contact-links">
        <a className="contact-us" href={LEGAL_LINKS.support} target="_blank" rel="noopener noreferrer">
          Customer Care
        </a>
        <a href={LEGAL_LINKS.privacy} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a href={LEGAL_LINKS.terms} target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>
        <a href={LEGAL_LINKS.cookies} target="_blank" rel="noopener noreferrer">
          Cookies Policy
        </a>
        <a href={LEGAL_LINKS.legal} target="_blank" rel="noopener noreferrer">
          Legal Notices
        </a>
        <a href={LEGAL_LINKS.eula} target="_blank" rel="noopener noreferrer">
          EULA
        </a>
      </div>
      <div className="mobile-gameloft-logo">
        <a href={LEGAL_LINKS.gameloft} target="_blank" rel="noopener noreferrer">
          <img src={GameloftLogo} alt="Gameloft logo" className="gameloft-logo" />
        </a>
      </div>
      <div className="bottom-text">
        <p>© 2022 Gameloft. All Rights Reserved. Gameloft and the logo Gameloft are trademarks of Gameloft in the US and/or other countries.</p>
        <p>App Store is a service mark of Apple Inc., registered in the U.S. and other countries.</p>
        <p>Google Play and the Google Play logo are trademarks of Google LLC.</p>
        <p>Copyright © 2010 by Ryoichi Tsunekawa. All rights reserved.</p>
        <p>Copyright © 2011-2012, Julieta Ulanovsky (julieta.ulanovsky@gmail.com), with Reserved Font Names ‘Montserrat’ </p>
        <p>
          This Font Software is licensed under the SIL Open Font License, Version 1.1. This license is available with a FAQ at:
          <a href="http://scripts.sil.org/OFL" target="_blank" rel="noopener noreferrer">
            http://scripts.sil.org/OFL
          </a>
        </p>
        <p>
          {/* eslint-disable-next-line */}
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              window["Didomi"]?.preferences.show();
            }}
          >
            Manage your cookies choices
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
